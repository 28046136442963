import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="imgTemp"
             src="https://avatars.githubusercontent.com/u/85170854?v=4">

        </img>

        <p>
          Stay tuned! Website coming soon.
        </p>
      </header>
    </div>
  );
}

export default App;
